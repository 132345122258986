.DesktopContainer {
    position: absolute;
    height: auto;
    background-color: #e8e8e8;
    z-index: 1;
    width: 80%;
    left: 0;
}

.AboutHeading {
    color: #171717;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 60px;
}

.AboutText {
    color: #171717;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 60px;
    margin-right: 60px;
}

.AboutText a {
    text-decoration: none;
    color: #171717;
    font-family: Inter;
}

.AboutText a:hover {
    text-decoration: none;
    color: #464646;
    font-family: Inter;
}

.timeline h3 {
    font-family: Inter;
}

.timeline h4 {
    font-family: Inter;
}

.timeline p {
    font-family: Inter;
}

.MobileContainer {
    position: absolute;
    height: auto;
    background-color: #e8e8e8;
    z-index: 1;
    width: auto;
    margin-top: 100vh;
    left: 0;
}

.AboutHeadingMobile {
    color: #171717;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 30px;
}

.AboutTextMobile {
    color: #171717;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 30px;
    margin-right: 30px;
}