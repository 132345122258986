.DesktopContainer {
    height: 100vh;
}

.desktopDate {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: fixed;
    left: 64px;
}

.desktopTime {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: fixed;
    left: 50%;
}

.desktopYear {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: fixed;
    right: 64px;
}

.desktopContact {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    position: fixed;
    left: 64px;
    bottom: 150px;
}

.desktopContact {
    color: #838383;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    position: fixed;
    left: 64px;
    bottom: 150px;
}

.desktopContact a {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}

.desktopContact a:hover {
    color: #838383;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}

.desktopName {
    color: #000;
    font-family: Inter;
    font-size: 128px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    left: 60px;
    position: fixed;
    z-index: 1;
    filter: invert(1);
    mix-blend-mode: difference;
}

.desktopPhoto {
    top: 320px;
    left: 60px;
    position: fixed;
    z-index: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.desktopSWE {
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 128px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    bottom: 0;
    right: 64px;
    position: fixed;
    z-index: 1;
    filter: invert(2);
    mix-blend-mode: difference;
}

.desktopDescription {
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    bottom: 50%;
    right: 64px;
    position: fixed;
    z-index: 1;
    filter: invert(2);
    mix-blend-mode: difference;
}

.nonlinks {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: default;
}

/* Mobile Styling */

.MobileContainer {
    height: 100vh;
    overflow-x: none;
}

.mobileContact {
    color: #838383;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    position: fixed;
    left: 10px;
    bottom: 30%;
}

.mobileContact a {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}

.mobileName {
    color: #000;
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    left: 10px;
    position: fixed;
    z-index: 1;
    filter: invert(1);
    mix-blend-mode: difference;
}

.mobilePhoto {
    top: 17%;
    left: 10px;
    width: 80%;
    position: fixed;
    z-index: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.mobileSWE {
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    bottom: 0;
    right: 20px;
    position: fixed;
    z-index: 1;
    filter: invert(2);
    mix-blend-mode: difference;
}

.mobileDescription {
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    bottom: 50%;
    right: 10px;
    position: fixed;
    z-index: 1;
    filter: invert(2);
    mix-blend-mode: difference;
}
